import { v4 as uuidV4 } from "uuid";
import { UUID } from "gx-npm-lib";
import { RequestDocumentType, SurveyUpdateType } from "../../form.types";

export const addRequestedDocument = (prevRequestedDocuments: RequestDocumentType[]) => {
  const newDocument: RequestDocumentType = {
    id: uuidV4() as UUID,
    index: prevRequestedDocuments.filter((opt) => !opt.isDeleted).length,
    name: "",
    updateType: SurveyUpdateType.NEW,
    attachedFileId: "" as UUID,
    attachedFileName: "",
  };
  return [...prevRequestedDocuments, newDocument];
};

export const removeRequestedDocumentFromExistingSurvey = (prevRequestedDocuments: RequestDocumentType[], id: UUID) => {
  let currentIndex = 0;
  return prevRequestedDocuments
    .map((requestedDocument) => {
      if (requestedDocument.isDeleted) {
        return requestedDocument;
      }
      if (requestedDocument.id === id) {
        const { updateType } = requestedDocument;
        return {
          ...requestedDocument,
          isDeleted: true,
          updateType: updateType === SurveyUpdateType.NEW ? SurveyUpdateType.NEW : SurveyUpdateType.DELETE,
        };
      }
      if (requestedDocument.index !== currentIndex) {
        requestedDocument.updateType = requestedDocument.updateType || SurveyUpdateType.UPDATE;
      }
      return { ...requestedDocument, index: currentIndex++ };
    })
    .filter((requestedDocument) => {
      return !requestedDocument.isDeleted || requestedDocument.updateType !== SurveyUpdateType.NEW;
    });
};

export const removeRequestedDocumentFromNewSurvey = (prevRequestedDocuments: RequestDocumentType[], id: UUID) => {
  return prevRequestedDocuments
    .filter((requestedDocument) => requestedDocument.id !== id)
    .map((requestedDocument, index) => ({ ...requestedDocument, index }));
};

export const updateRequestedDocumentName = (prevRequestedDocuments: RequestDocumentType[], id: UUID, name: string) => {
  return prevRequestedDocuments.map((requestedDocument) => {
    if (requestedDocument.id !== id) {
      return requestedDocument;
    }
    const updateType = requestedDocument.updateType || SurveyUpdateType.UPDATE;
    return { ...requestedDocument, name, updateType };
  });
};
