const AttachIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="*Attach file">
        <path
          id="attach_file"
          d="M17.6733 15.6443C17.6733 17.2683 17.1082 18.6502 15.978 19.79C14.848 20.93 13.4724 21.5 11.8513 21.5C10.2301 21.5 8.85286 20.93 7.71953 19.79C6.5862 18.6502 6.01953 17.2683 6.01953 15.6443V6.65375C6.01953 5.49992 6.4202 4.51917 7.22153 3.7115C8.0227 2.90383 9.0002 2.5 10.154 2.5C11.3079 2.5 12.2854 2.90383 13.0868 3.7115C13.8881 4.51917 14.2888 5.49992 14.2888 6.65375V15.1635C14.2888 15.8403 14.053 16.4182 13.5815 16.897C13.1099 17.3758 12.5352 17.6152 11.8575 17.6152C11.1797 17.6152 10.6014 17.3775 10.1225 16.902C9.6437 16.4265 9.40428 15.847 9.40428 15.1635V7.13475C9.40428 6.92225 9.47611 6.74408 9.61978 6.60025C9.76361 6.45658 9.94186 6.38475 10.1545 6.38475C10.367 6.38475 10.5451 6.45658 10.6888 6.60025C10.8323 6.74408 10.904 6.92225 10.904 7.13475V15.1635C10.904 15.4315 10.9941 15.6572 11.1743 15.8405C11.3544 16.0238 11.5785 16.1155 11.8465 16.1155C12.1144 16.1155 12.3384 16.0238 12.5185 15.8405C12.6987 15.6572 12.7888 15.4315 12.7888 15.1635V6.64425C12.7784 5.90575 12.5219 5.28042 12.0193 4.76825C11.5166 4.25608 10.8949 4 10.154 4C9.41636 4 8.79286 4.25767 8.28353 4.773C7.7742 5.2885 7.51953 5.91542 7.51953 6.65375V15.6443C7.5092 16.8533 7.92711 17.8814 8.77328 18.7288C9.61945 19.5763 10.6466 20 11.8548 20C13.0458 20 14.0582 19.5763 14.892 18.7288C15.7257 17.8814 16.1528 16.8533 16.1733 15.6443V7.13475C16.1733 6.92225 16.2452 6.74408 16.389 6.60025C16.5329 6.45658 16.7111 6.38475 16.9238 6.38475C17.1363 6.38475 17.3144 6.45658 17.458 6.60025C17.6015 6.74408 17.6733 6.92225 17.6733 7.13475V15.6443Z"
          fill="#0000A9"
        />
      </g>
    </svg>
  );
};

export default AttachIcon;
