import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdditionCircledIcon } from "gx-npm-icons";
import { BaseTextArea, IconButton, MiniButton, TooltipV2, TypographyComponent } from "gx-npm-ui";
import {
  SurveySetupDeletedFileMessage,
  SurveySetupStateName,
  SurveySetupUpdatedRequestedDocFileMessage,
  UUID,
} from "gx-npm-lib";
import { IconDocument } from "../../../../ui/icons/form.icons";
import AttachIcon from "../company-name-instructions/instructions-attached-files/attach.icon";
import { colorPalette } from "gx-npm-common-styles";
import { navigateToUrl } from "single-spa";
import { useParams } from "react-router-dom";
import { createStateSubscription, destroyStateSubscription, updateStateSubject } from "gx-npm-messenger-util";
import { SurveyUpdateType } from "../../form.types";
import FileExtensionSmallIconDisplayComponent from "../company-name-instructions/file-extension-icon-display/file-extension-small-icon-display.component";
import styles from "./request-documents-list-v2.styles.module.scss";
import classNames from "classnames";
import ClearIcon from "../../../../ui/icons/clear.icon";
import TrashIcon from "./trash.icon";
import { v4 as uuidV4 } from "uuid";
import { useFormContext } from "../../context/form.context";

const RequestDocumentsListV2 = () => {
  const { t } = useTranslation();
  const [isLastItemFocused, setIsLastItemFocused] = useState(false);
  const { initiativeId = "", surveyId = "" } = useParams<{ initiativeId: UUID; surveyId: UUID }>();
  const { requestedDocuments, setRequestedDocuments, setHasUpdates, isExistingSurvey } = useFormContext();
  const requestedDocumentsCount = requestedDocuments.filter((doc) => !doc.isDeleted).length;

  useEffect(() => {
    const name = SurveySetupStateName.SURVEY_SETUP_UPDATED_REQUESTED_DOC_FILE;
    const callback = (message: SurveySetupUpdatedRequestedDocFileMessage) => {
      setRequestedDocuments((prev) => {
        return prev.map((requestedDocument) => {
          if (requestedDocument.id !== message?.requestDocId) {
            return requestedDocument;
          }
          return {
            ...requestedDocument,
            attachedFileId: message?.fileId,
            attachedFileName: message?.fileName,
            updateType:
              requestedDocument.updateType === SurveyUpdateType.NEW ? SurveyUpdateType.NEW : SurveyUpdateType.UPDATE,
          };
        });
      });
      setHasUpdates(true);
    };
    const subscription = createStateSubscription(name, callback);
    return () => {
      destroyStateSubscription(subscription);
    };
  }, [isExistingSurvey, setHasUpdates, setRequestedDocuments]);

  useEffect(() => {
    const name = SurveySetupStateName.SURVEY_SETUP_DELETED_FILE;
    const callback = (message: SurveySetupDeletedFileMessage) => {
      setRequestedDocuments((prev) => {
        return prev.map((requestedDocument) => {
          if (requestedDocument.attachedFileId !== message.fileId) {
            return requestedDocument;
          }
          return {
            ...requestedDocument,
            attachedFileId: "",
            attachedFileName: "",
            updateType: SurveyUpdateType.UPDATE,
          };
        });
      });
      setHasUpdates(true);
    };
    const subscription = createStateSubscription(name, callback);
    return () => {
      destroyStateSubscription(subscription);
    };
  }, [setHasUpdates, setRequestedDocuments]);

  const handleAddRequestDocument = () => {
    setRequestedDocuments((prev) => {
      const newDocument = {
        id: uuidV4() as UUID,
        index: prev.filter((opt) => !opt.isDeleted).length,
        name: "",
        updateType: SurveyUpdateType.NEW,
        attachedFileId: "" as UUID,
        attachedFileName: "",
      };
      return [...prev, newDocument];
    });
    setIsLastItemFocused(true);
    setHasUpdates(true);
  };

  const handleBlurTextArea = (id: UUID, name: string) => {
    let hasUpdates = false;
    setRequestedDocuments((prev) => {
      return prev.map((requestedDocument) => {
        if (requestedDocument.id !== id || requestedDocument.name === name) {
          return requestedDocument;
        }
        const updateType = requestedDocument.updateType || SurveyUpdateType.UPDATE;
        hasUpdates = true;
        return { ...requestedDocument, name, updateType };
      });
    });
    setIsLastItemFocused(false);
    setHasUpdates(hasUpdates);
  };

  const handleAddAttachment = (requestedDocName: string, attachedFileId: UUID, requestedDocId: UUID) => {
    updateStateSubject(SurveySetupStateName.SURVEY_SETUP_EXISTING_REQUESTED_DOC_FILE, {
      requestedDocName,
      attachedFileId,
      requestedDocId,
    });

    const url = surveyId
      ? `/s/evaluation/${initiativeId}/questionnaire/setup/${surveyId}/files`
      : `/s/evaluation/${initiativeId}/questionnaire/setup/files`;
    navigateToUrl(url);
  };

  const handleRemoveAttachment = (id: UUID) => {
    setRequestedDocuments((prev) => {
      return prev.map((requestedDocument) => {
        if (requestedDocument.id !== id) {
          return requestedDocument;
        }
        if (isExistingSurvey) {
          const { updateType } = requestedDocument;
          return {
            ...requestedDocument,
            attachedFileId: "" as UUID,
            attachedFileName: "",
            updateType: updateType === SurveyUpdateType.NEW ? SurveyUpdateType.NEW : SurveyUpdateType.UPDATE,
          };
        } else {
          return {
            ...requestedDocument,
            attachedFileId: "" as UUID,
            attachedFileName: "",
          };
        }
      });
    });
    setHasUpdates(true);
  };

  const handleRemoveRequestedDocument = (id: UUID) => {
    setRequestedDocuments((prev) => {
      if (isExistingSurvey) {
        let currentIndex = 0;
        return prev
          .map((requestedDocument) => {
            if (requestedDocument.isDeleted) {
              return requestedDocument;
            }
            if (requestedDocument.id === id) {
              const { updateType } = requestedDocument;
              return {
                ...requestedDocument,
                isDeleted: true,
                updateType: updateType === SurveyUpdateType.NEW ? SurveyUpdateType.NEW : SurveyUpdateType.DELETE,
              };
            }
            if (requestedDocument.index !== currentIndex) {
              requestedDocument.updateType = requestedDocument.updateType || SurveyUpdateType.UPDATE;
            }
            return { ...requestedDocument, index: currentIndex++ };
          })
          .filter((requestedDocument) => {
            return !requestedDocument.isDeleted || requestedDocument.updateType !== SurveyUpdateType.NEW;
          });
      } else {
        return prev
          .filter((requestedDocument) => requestedDocument.id !== id)
          .map((requestedDocument, index) => ({ ...requestedDocument, index }));
      }
    });
    setHasUpdates(true);
  };

  return (
    <div className={styles.root}>
      <ul aria-label={t("requested documents list")} className={styles.reqDocList}>
        {requestedDocuments.map((document, index) => {
          const fileParts = document.attachedFileName?.split(".");
          const fileName = fileParts?.slice(0, -1).join(".");
          const fileExt = fileParts ? fileParts[fileParts.length - 1].toLocaleLowerCase() : "";
          return (
            !document.isDeleted && (
              <li className={classNames(styles.listItem)} key={document.id}>
                <div className={styles.listItemTop}>
                  <div className={styles.iconDocument}>
                    <IconDocument />
                  </div>
                  <BaseTextArea
                    autoFocus={index === requestedDocumentsCount - 1 && isLastItemFocused}
                    onBlur={(e) => handleBlurTextArea(document.id, e.currentTarget.value)}
                    placeholder={t("Enter requested document here")}
                    value={document.name}
                    rootClassName={styles.reqDocTextArea}
                    onFocus={() => setIsLastItemFocused(true)}
                  />
                  <TooltipV2
                    PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
                    placement="top"
                    title={t("Attach a file to be completed or signed by the vendor.")}
                    rootClassName={styles.toolTipText}
                  >
                    <div>
                      <IconButton
                        ariaLabel={t("add attachment")}
                        height={48}
                        onClick={() => handleAddAttachment(document.name, document.attachedFileId, document.id)}
                        width={48}
                        fillColor={colorPalette.neutrals.coal.hex}
                        rootClassName={styles.attachButton}
                      >
                        <AttachIcon />
                      </IconButton>
                    </div>
                  </TooltipV2>
                  <TooltipV2
                    PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
                    placement="top"
                    title={t("Delete")}
                  >
                    <div>
                      <IconButton
                        ariaLabel={t("remove document")}
                        hoverFill={colorPalette.status.poisonCherry.hex}
                        onClick={() => handleRemoveRequestedDocument(document.id)}
                        transparentBackground
                        rootClassName={styles.deleteButton}
                      >
                        <TrashIcon />
                      </IconButton>
                    </div>
                  </TooltipV2>
                </div>
                {fileName && fileExt && (
                  <div className={styles.attachedFileRoot}>
                    <div className={styles.attachedFile}>
                      <FileExtensionSmallIconDisplayComponent fileExt={fileExt} />
                      <TypographyComponent rootClassName={styles.fileName} styling="p3" boldness="regular">
                        {fileName}.{fileExt}
                      </TypographyComponent>
                      <TooltipV2
                        placement="top"
                        PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
                        title={t("Remove document")}
                      >
                        <div>
                          <IconButton
                            ariaLabel={t("remove attachment")}
                            className={styles.clearSelection}
                            focusBackgroundColor="transparent"
                            height={24}
                            hoverBackgroundColor="transparent"
                            onClick={() => handleRemoveAttachment(document.id)}
                            width={24}
                          >
                            <ClearIcon />
                          </IconButton>
                        </div>
                      </TooltipV2>
                    </div>
                  </div>
                )}
              </li>
            )
          );
        })}
      </ul>
      <MiniButton onClick={handleAddRequestDocument} rootClassName={styles.addDocButton}>
        <AdditionCircledIcon />
        <TypographyComponent rootClassName={styles.addDocButtonText} boldness="medium" element="p">
          {t("Add document request")}
        </TypographyComponent>
      </MiniButton>
    </div>
  );
};

export default RequestDocumentsListV2;
