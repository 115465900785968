import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorInteractionLightBerry = colorPalette.interactions.lightBerry.hex;
const colorNeutralCarbon = colorPalette.neutrals.carbon.hex;
const colorNeutralIron = colorPalette.neutrals.iron.hex;
const colorNeutralSilver = colorPalette.neutrals.silver.hex;
const colorNeutralPearl = colorPalette.neutrals.pearl.hex;

const weightMediumVar = weightPalette.medium.variation;
const weightMediumAmt = weightPalette.medium.amount;
const weightRegAmt = weightPalette.regular.amount;
const weightRegVar = weightPalette.regular.variation;

const dragAndDropRootStyles = {
  root: {
    marginBottom: 24,
    position: "relative" as string as "relative",
  },
  listItems: {
    margin: 0,
    padding: 0,
  },
};

const listItemTextAreaStyles = {
  root: {
    width: "100%",
    "& textarea": {
      fontSize: 16,
      fontVariationSettings: weightMediumVar,
      fontWeight: weightMediumAmt,
      width: "100%",
    },
    "& .gx-drag-drop-textarea-container": {
      width: "100%",
      "&.parent-hover": {
        "& textarea": {
          backgroundColor: colorNeutralPearl,
        },
      },
    },
    "& .gx-drag-drop-textarea-container.ff4101": {
      width: "100%",
      "&.parent-hover textarea": {
        backgroundColor: colorInteractionLightBerry,
        outline: `1px solid ${colorNeutralCarbon}`,
        margin: "1px 0 -1px 0",
        paddingTop: 13,
      },
    },
  },
};

const listItemDeleteButtonStyles = {
  root: {
    marginLeft: "auto",
    "& .ff4101:focus-visible": {
      outline: `2px solid ${colorPalette.interactions.defaultCta.hex}`,
    },
  },
};

const listItemStyles = () => ({
  listItem: {
    display: "flex" as string as "flex",
    backgroundColor: "var(--color-white)",
    borderRadius: 0,
    boxShadow: `inset 0px -1px 0px ${colorNeutralSilver}`,
    color: "var(--color-neutrals-carbon)",
    listStyleType: "none",
    paddingBottom: 8,
    paddingTop: 8,
    "& input": {
      boxSizing: "border-box",
      fontVariationSettings: weightRegVar,
      fontWeight: weightRegAmt,
      height: 48,
      paddingBottom: 13,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 14,
      "&:placeholder-shown": {
        color: colorNeutralIron,
        fontVariationSettings: weightRegVar,
        fontWeight: weightRegAmt,
      },
    },
    "&.complete": {
      "& input": {
        color: colorNeutralIron,
        fontVariationSettings: weightRegVar,
        fontWeight: weightRegAmt,
      },
    },
    "&.not-complete": {
      "& input": {
        color: colorNeutralCarbon,
        fontVariationSettings: weightPalette.medium.variation,
        fontWeight: weightPalette.medium.amount,
      },
    },
    "&:hover": {
      backgroundColor: colorNeutralPearl,
      cursor: "pointer",
    },
    "&.ff4101:hover": {
      backgroundColor: colorInteractionLightBerry,
    },
    "&.edit": {
      backgroundColor: colorInteractionLightBerry,
      "& .gx-drag-drop-textarea-container": {
        width: "100%",
        "&.parent-hover": {
          "& textarea": {
            backgroundColor: colorInteractionLightBerry,
          },
        },
      },
      "& .gx-drag-drop-textarea-container.ff4101": {
        width: "100%",
        "& textarea": {
          margin: "1px 0 -1px 0",
          paddingTop: 13,
        },
        "&.parent-hover textarea": {
          backgroundColor: colorInteractionLightBerry,
          outline: `2px solid ${colorPalette.interactions.defaultCta.hex}`,
        },
      },
    },
    "& fieldset": {
      border: 0,
      borderRadius: 0,
      paddingLeft: 0,
    },
  },
  classClass: { clear: "both" as string as "both" },
  draggable: {
    float: "left" as string as "left",
    height: 48,
    minWidth: 48,
    "& svg": {
      cursor: "grab",
    },
  },
});

export { dragAndDropRootStyles, listItemDeleteButtonStyles, listItemStyles, listItemTextAreaStyles };
