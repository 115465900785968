import { colorPalette } from "gx-npm-common-styles";

const requestDocumentsStyles = {
  requestedDocuments: {
    paddingBottom: 26,
  },
  attachedDocTextIconWrapper: {
    display: "flex",
    marginTop: 28,
  },
  attachedDocText: {
    marginLeft: 16,
  },
  divider: {
    height: 1,
    backgroundColor: colorPalette.neutrals.silver.hex,
  },
};

const requestDocumentsListStyles = {
  root: {},
};

const requestDocumentsListDeleteButtonStyles = {
  root: {
    marginLeft: "auto",
  },
};

export { requestDocumentsStyles, requestDocumentsListStyles, requestDocumentsListDeleteButtonStyles };
