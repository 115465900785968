// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YspngjspT2toQFonGIAq{background-size:cover;display:inline-block;height:16px;margin-right:8px;width:16px;padding:0}`, "",{"version":3,"sources":["webpack://./src/sections/form/components/company-name-instructions/file-extension-icon-display/file-extension-small-icon-display.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,oBAAA,CACA,WAAA,CACA,gBAAA,CACA,UAAA,CACA,SAAA","sourcesContent":[".root {\n  background-size: cover;\n  display: inline-block;\n  height: 16px;\n  margin-right: 8px;\n  width: 16px;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `YspngjspT2toQFonGIAq`
};
export default ___CSS_LOADER_EXPORT___;
