import React from "react";
import classNames from "classnames";
import { IconCheckMarkComplete, IconCheckMarkNotComplete } from "../../../../ui/icons/form.icons";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "gx-npm-ui";
import { GCOM_4101_docManagementV2 } from "../../../../lib/feature-flags";

const FormSectionStatus = ({ isComplete = false }: { isComplete?: boolean }) => {
  return (
    <FeatureFlagBooleanContainer flagName={GCOM_4101_docManagementV2}>
      <FeatureFlagBooleanOff>
        <div
          aria-label={`form section status ${isComplete ? "complete" : "not complete"}`}
          className={classNames("gx-form-section-status", isComplete && "complete")}
          style={{ marginLeft: -48, position: "absolute" }}
        >
          {isComplete ? <IconCheckMarkComplete /> : <IconCheckMarkNotComplete />}
        </div>
      </FeatureFlagBooleanOff>
      <FeatureFlagBooleanOn>
        <div
          aria-label={`form section status ${isComplete ? "complete" : "not complete"}`}
          className={classNames("gx-form-section-status", isComplete && "complete")}
          style={{ marginLeft: -48, position: "absolute" }}
          role="status"
        >
          {isComplete ? <IconCheckMarkComplete /> : <IconCheckMarkNotComplete />}
        </div>
      </FeatureFlagBooleanOn>
    </FeatureFlagBooleanContainer>
  );
};

export default FormSectionStatus;
