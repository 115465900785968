import { useTranslation } from "react-i18next";
import { IconButton, MiniButton, TooltipV2, TypographyComponent } from "gx-npm-ui";
import AttachIcon from "./attach.icon";
import FileExtensionSmallIconDisplayComponent from "../file-extension-icon-display/file-extension-small-icon-display.component";
import styles from "./instructions-attached-files.styles.module.scss";
import ClearIcon from "../../../../../ui/icons/clear.icon";
import React, { useEffect } from "react";
import {
  SurveySetupDeletedFileMessage,
  SurveySetupStateName,
  SurveySetupUpdatedInstructionFilesMessage,
  UUID,
} from "gx-npm-lib";
import { useFormContext } from "../../../context/form.context";
import { navigateToUrl } from "single-spa";
import { useParams } from "react-router-dom";
import { createStateSubscription, destroyStateSubscription, updateStateSubject } from "gx-npm-messenger-util";

const InstructionsAttachedFilesComponent = () => {
  const { t } = useTranslation();
  const { instructionsAttachedFiles, setInstructionsAttachedFiles, setHasUpdates } = useFormContext();
  const { initiativeId = "", surveyId = "" } = useParams<{ initiativeId: UUID; surveyId: UUID }>();

  useEffect(() => {
    const name = SurveySetupStateName.SURVEY_SETUP_UPDATED_INSTRUCTION_FILES;
    const callback = (message: SurveySetupUpdatedInstructionFilesMessage) => {
      setInstructionsAttachedFiles(message?.files || []);
      setHasUpdates(true);
    };
    const subscription = createStateSubscription(name, callback);
    return () => {
      destroyStateSubscription(subscription);
    };
  }, [setHasUpdates, setInstructionsAttachedFiles]);

  useEffect(() => {
    const name = SurveySetupStateName.SURVEY_SETUP_DELETED_FILE;
    const callback = (message: SurveySetupDeletedFileMessage) => {
      setInstructionsAttachedFiles((prev) => {
        return prev.filter((file) => file.id !== message.fileId);
      });
      setHasUpdates(true);
    };
    const subscription = createStateSubscription(name, callback);
    return () => {
      destroyStateSubscription(subscription);
    };
  }, [setHasUpdates, setInstructionsAttachedFiles]);

  const handleRemoveAttachment = (id: UUID) => {
    setInstructionsAttachedFiles(instructionsAttachedFiles.filter((file) => file.id !== id));
    setHasUpdates(true);
  };

  const handleAddAttachment = () => {
    updateStateSubject(SurveySetupStateName.SURVEY_SETUP_EXISTING_INSTRUCTION_FILES, {
      fileIds: instructionsAttachedFiles.map((file) => file.id),
    });
    navigateToUrl(`/s/evaluation/${initiativeId}/questionnaire/setup${surveyId ? "/" : ""}${surveyId}/files`);
  };
  return (
    <div className={styles.root}>
      <MiniButton rootClassName={styles.addAttachmentButton} onClick={handleAddAttachment}>
        <AttachIcon />
        <TypographyComponent rootClassName={styles.addAttachmentText} boldness="medium" element="p">
          {t("Add an attachment")}
        </TypographyComponent>
      </MiniButton>
      <div>
        <ul className={styles.attachedFilesList}>
          {instructionsAttachedFiles
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .map((file) => {
              const fileParts = file.name.split(".");
              const fileName = fileParts.slice(0, -1).join(".");
              const fileExt = fileParts[fileParts.length - 1].toLocaleLowerCase();
              return (
                <li key={file.id}>
                  <div className={styles.attachedFile}>
                    <FileExtensionSmallIconDisplayComponent fileExt={fileExt} />
                    <TypographyComponent rootClassName={styles.fileName} styling="p3" boldness="regular">
                      {fileName}.{fileExt}
                    </TypographyComponent>
                    <TooltipV2
                      placement="top"
                      PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
                      title={t("Remove document")}
                    >
                      <div>
                        <IconButton
                          ariaLabel={t("remove attachment")}
                          className={styles.clearSelection}
                          focusBackgroundColor="transparent"
                          height={24}
                          hoverBackgroundColor="transparent"
                          onClick={() => handleRemoveAttachment(file.id)}
                          width={24}
                        >
                          <ClearIcon />
                        </IconButton>
                      </div>
                    </TooltipV2>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default InstructionsAttachedFilesComponent;
