import { isValidResponse, postAsyncRequest, putAsyncRequest, UUID } from "gx-npm-lib";
import { FormConfigType, ResponseOptionType, SurveyFormDataPayload } from "../../form.types";

const submitFormData = async (
  formConfig: FormConfigType,
  surveyFormData: SurveyFormDataPayload,
  onSuccessResponse: (id: UUID) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { initId, surveyId } = formConfig;
  const isExistingSurvey = !!surveyId;
  const urlSuffix = isExistingSurvey ? `update/${surveyId}` : "create";
  const url = `/api/v3/initiatives/${initId}/survey/${urlSuffix}`;

  const asyncRequest = isExistingSurvey ? putAsyncRequest : postAsyncRequest;
  const response = await asyncRequest(url, surveyFormData);
  if (isValidResponse(response) && !!response.data.data) {
    onSuccessResponse(response.data.data);
    pushFullStoryEvents({
      surveyId,
      requestedDocumentCount: surveyFormData.requestedDocuments.length,
      responseOptions: surveyFormData.responseOptions,
      selectedCategoriesCount: surveyFormData.selectedRequirementCategoryIds.length,
    });
  } else {
    onErrorResponse(response);
  }
};

const pushFullStoryEvents = ({
  surveyId,
  requestedDocumentCount,
  responseOptions,
  selectedCategoriesCount,
}: {
  surveyId: UUID;
  requestedDocumentCount: number;
  responseOptions: Array<ResponseOptionType>;
  selectedCategoriesCount: number;
}) => {
  if (!window.FS || surveyId) {
    return;
  }
  window.FS.event("survey created counts", {
    "cats selected count": selectedCategoriesCount,
    "docs count": requestedDocumentCount,
  });
  responseOptions.map((responseOption) => {
    window?.FS?.event("response option added", { "option name": responseOption.name });
  });
};

export { submitFormData };
