import { StylesProvider } from "@material-ui/core";
import { createGenerateClassName } from "@material-ui/core/styles";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FormContextProvider } from "./sections/form/context/form.context";
import HandleRedirects from "./handle-redirects";
import App from "./app.component";

const generateClassName = createGenerateClassName({ seed: "SurveyBuilderApp" });
const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <FormContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/s/evaluation/:initiativeId/questionnaire/setup" element={<App />} />
              <Route path="/s/evaluation/:initiativeId/questionnaire/setup/files" element={<App />} />
              <Route path="/s/evaluation/:initiativeId/questionnaire/setup/:surveyId" element={<App />} />
              <Route path="/s/evaluation/:initiativeId/questionnaire/setup/:surveyId/files" element={<App />} />
              <Route path="*" element={<HandleRedirects />} />
            </Routes>
          </BrowserRouter>
        </FormContextProvider>
      </StylesProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
