import { UUID } from "gx-npm-lib";
import { SnackbarBanner } from "gx-npm-ui";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import useFormLoad from "./sections/form/context/load/use-form-load.hook";
import FooterActionBarComponent from "./sections/footer/footer-action-bar.component";
import HeaderComponent from "./sections/header/header.component";
import SurveyFormComponent from "./sections/form/survey-form.component";

const App = () => {
  const { initiativeId = "", surveyId = "" } = useParams<{ initiativeId: UUID; surveyId: UUID }>();
  const { hasError, setHasError } = useFormLoad(initiativeId, surveyId);

  return (
    <Fragment>
      <HeaderComponent />
      <SurveyFormComponent />
      <FooterActionBarComponent initId={initiativeId} surveyId={surveyId} />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} />
    </Fragment>
  );
};

export default App;
