import {
  FileTypeDocSmallIcon,
  FileTypeDocxSmallIcon,
  FileTypeXlsSmallIcon,
  FileTypeXlsxSmallIcon,
  FileTypeTextSmallIcon,
  FileTypePPTSmallIcon,
  FileTypePPTxSmallIcon,
  FileTypePdfSmallIcon,
  FileTypeJpgSmallIcon,
  FileTypePngSmallIcon,
  FileTypeMovSmallIcon,
  FileTypeMp4SmallIcon,
} from "gx-npm-icons";
import styles from "./file-extension-small-icon-display.styles.module.scss";

type FileIconDisplayProps = { fileExt: string };

const FileExtensionSmallIconDisplayComponent = ({ fileExt }: FileIconDisplayProps) => {
  //todo add the remaining icons once in figma
  return (
    <div className={styles.root}>
      {fileExt === "doc" && <FileTypeDocSmallIcon />}
      {fileExt === "docx" && <FileTypeDocxSmallIcon />}
      {fileExt === "pdf" && <FileTypePdfSmallIcon />}
      {fileExt === "ppt" && <FileTypePPTSmallIcon />}
      {fileExt === "pptx" && <FileTypePPTxSmallIcon />}
      {fileExt === "txt" && <FileTypeTextSmallIcon />}
      {fileExt === "xls" && <FileTypeXlsSmallIcon />}
      {fileExt === "xlsx" && <FileTypeXlsxSmallIcon />}
      {(fileExt === "jpeg" || fileExt === "jpg") && <FileTypeJpgSmallIcon />}
      {fileExt === "png" && <FileTypePngSmallIcon />}
      {fileExt === "mov" && <FileTypeMovSmallIcon />}
      {fileExt === "mp4" && <FileTypeMp4SmallIcon />}
    </div>
  );
};

export default FileExtensionSmallIconDisplayComponent;
