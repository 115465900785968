const TrashIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.93347 20C7.4715 20 7.07606 19.8394 6.74713 19.5182C6.4182 19.1971 6.25373 18.811 6.25373 18.3599V6.43247H5.6791C5.48669 6.43247 5.32545 6.36939 5.19537 6.24323C5.06512 6.11708 5 5.96083 5 5.77449C5 5.58798 5.06512 5.43003 5.19537 5.30065C5.32545 5.17109 5.48669 5.10632 5.6791 5.10632H9.49254V4.80028C9.49254 4.57943 9.57264 4.39079 9.73284 4.23437C9.89286 4.07812 10.086 4 10.3122 4H13.6878C13.914 4 14.1071 4.07812 14.2672 4.23437C14.4274 4.39079 14.5075 4.57943 14.5075 4.80028V5.10632H18.3209C18.5133 5.10632 18.6746 5.16939 18.8046 5.29555C18.9349 5.4217 19 5.57795 19 5.76429C19 5.9508 18.9349 6.10875 18.8046 6.23813C18.6746 6.36769 18.5133 6.43247 18.3209 6.43247H17.7463V18.3489C17.7463 18.8124 17.5818 19.2035 17.2529 19.5221C16.9239 19.8407 16.5285 20 16.0665 20H7.93347ZM16.3881 6.43247H7.61194V18.3599C7.61194 18.4515 7.64206 18.5268 7.70231 18.5856C7.76256 18.6444 7.83961 18.6738 7.93347 18.6738H16.0665C16.1604 18.6738 16.2374 18.6444 16.2977 18.5856C16.3579 18.5268 16.3881 18.4515 16.3881 18.3599V6.43247ZM10.5885 16.8376C10.7794 16.8376 10.9411 16.7741 11.0738 16.6471C11.2063 16.52 11.2726 16.3624 11.2726 16.1746V8.93175C11.2726 8.74388 11.2081 8.58635 11.079 8.45918C10.9498 8.33217 10.7897 8.26867 10.5987 8.26867C10.4078 8.26867 10.2461 8.33217 10.1134 8.45918C9.98088 8.58635 9.91463 8.74388 9.91463 8.93175V16.1746C9.91463 16.3624 9.97914 16.52 10.1082 16.6471C10.2374 16.7741 10.3975 16.8376 10.5885 16.8376ZM13.4013 16.8376C13.5922 16.8376 13.7539 16.7741 13.8866 16.6471C14.0191 16.52 14.0854 16.3624 14.0854 16.1746V8.93175C14.0854 8.74388 14.0209 8.58635 13.8918 8.45918C13.7626 8.33217 13.6025 8.26867 13.4115 8.26867C13.2206 8.26867 13.0589 8.33217 12.9262 8.45918C12.7937 8.58635 12.7274 8.74388 12.7274 8.93175V16.1746C12.7274 16.3624 12.7919 16.52 12.921 16.6471C13.0502 16.7741 13.2103 16.8376 13.4013 16.8376Z"
        fill="#676565"
      />
    </svg>
  );
};

export default TrashIcon;
