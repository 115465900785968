import { createContext, ReactNode, useContext, useState } from "react";
import { UUID } from "gx-npm-lib";
import {
  CategoryType,
  FormContextValue,
  RequestDocumentType,
  ResponseOptionType,
  InstructionsAttachedFile,
} from "../form.types";

const FormContext = createContext<FormContextValue | null>(null);

const useFormContext = () => {
  const responseOptionsContext = useContext(FormContext);

  if (responseOptionsContext === null) {
    throw new Error("useFormContext must be inside a FormContextProvider");
  }
  return responseOptionsContext;
};

const FormContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [availableCategories, setAvailableCategories] = useState<CategoryType[]>([]);
  const [companyName, setCompanyName] = useState<string>("");
  const [hasSurveyRecipientViewed, setHasSurveyRecipientViewed] = useState<boolean>(false);
  const [hasUpdates, setHasUpdates] = useState<boolean>(false);
  const [instructions, setInstructions] = useState<string>("");
  const [isExistingSurvey, setIsExistingSurvey] = useState<boolean>(false);
  const [isNotifyingRecipients, setIsNotifyingRecipients] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [originalSelectedCategories, setOriginalSelectedCategories] = useState<UUID[]>([]);
  const [requestedDocuments, setRequestedDocuments] = useState<RequestDocumentType[]>([]);
  const [responseOptions, setResponseOptions] = useState<ResponseOptionType[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<UUID[]>([]);
  const [instructionsAttachedFiles, setInstructionsAttachedFiles] = useState<InstructionsAttachedFile[]>([]);

  const contextValue = {
    availableCategories,
    companyName,
    hasSurveyRecipientViewed,
    hasUpdates,
    instructions,
    isExistingSurvey,
    isNotifyingRecipients,
    notificationMessage,
    originalSelectedCategories,
    requestedDocuments,
    responseOptions,
    selectedCategories,
    instructionsAttachedFiles,
    setAvailableCategories,
    setCompanyName,
    setHasSurveyRecipientViewed,
    setHasUpdates,
    setInstructions,
    setIsExistingSurvey,
    setIsNotifyingRecipients,
    setNotificationMessage,
    setOriginalSelectedCategories,
    setRequestedDocuments,
    setResponseOptions,
    setSelectedCategories,
    setInstructionsAttachedFiles,
  };

  return <FormContext.Provider value={contextValue}>{props.children}</FormContext.Provider>;
};

export { FormContext, FormContextProvider, useFormContext };
