import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AdditionCircledIcon, PlusIcon } from "gx-npm-icons";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  MiniButton,
  TooltipV2,
} from "gx-npm-ui";
import { UUID } from "gx-npm-lib";
import DragDropList from "../../../../ui/drag-drop-list/drag-drop-list.component";
import DragDropListItem from "../../../../ui/drag-drop-list/drag-drop-list-item.component";
import DragDropListItemDeleteComponent from "../../../../ui/drag-drop-list/drag-drop-list-item-delete.component";
import DragDropListItemTextAreaComponent from "../../../../ui/drag-drop-list/drag-drop-list-item-textarea.component";
import useFormResponseOptions from "../../context/response-options/use-form-response-options.hook";
import styles from "./response-options-list.styles";
import { GCOM_4101_docManagementV2 } from "../../../../lib/feature-flags";
import { colorPalette } from "gx-npm-common-styles";

const useStyles = makeStyles(() => styles);
const ResponseOptionsList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLastItemFocused, setIsLastItemFocused] = useState(false);
  const {
    handleAddResponseOption,
    handleMoveResponseOption,
    handleRemoveResponseOption,
    handleUpdateResponseOptionName,
    responseOptions,
  } = useFormResponseOptions();

  const [isAddResponseDisabled, setIsAddResponseDisabled] = useState(false);

  const handleAddResponse = () => {
    handleAddResponseOption();
    setIsLastItemFocused(true);
  };

  const handleBlurTextArea = (id: UUID, value: string) => {
    handleUpdateResponseOptionName(id, value);
    setIsLastItemFocused(false);
  };

  useEffect(() => {
    const activeResponseOptionsCount = responseOptions.filter((option) => !option.isDeleted).length;
    setIsAddResponseDisabled(activeResponseOptionsCount >= 5);
  }, [responseOptions]);

  return (
    <div aria-label={t("response options list")}>
      <DragDropList itemList={responseOptions} onDragFinish={handleMoveResponseOption} rootClassName={classes.root}>
        {responseOptions.map((option, index) =>
          !option.isDeleted ? (
            <DragDropListItem
              id={option.id}
              index={index}
              key={option.id}
              renderItem={(params: { hover: boolean; setEdit: (isEdit: boolean) => void }) => {
                return (
                  <Fragment>
                    <FeatureFlagBooleanContainer flagName={GCOM_4101_docManagementV2}>
                      <FeatureFlagBooleanOff>
                        <DragDropListItemTextAreaComponent
                          hover={params.hover}
                          maxAllowedChars={70}
                          isLastItemFocused={
                            index === responseOptions.filter((opt) => !opt.isDeleted).length - 1 && isLastItemFocused
                          }
                          onBlur={(e) => handleBlurTextArea(option.id, e.currentTarget.value)}
                          setEdit={params.setEdit}
                          showCharCountOnFocus={true}
                          showCharCountOnHover={true}
                          value={option.name}
                        />
                        <DragDropListItemDeleteComponent
                          hover={params.hover}
                          onClick={() => handleRemoveResponseOption(option.id)}
                        />
                      </FeatureFlagBooleanOff>
                      <FeatureFlagBooleanOn>
                        <DragDropListItemTextAreaComponent
                          hover={params.hover}
                          maxAllowedChars={70}
                          isLastItemFocused={
                            index === responseOptions.filter((opt) => !opt.isDeleted).length - 1 && isLastItemFocused
                          }
                          onBlur={(e) => handleBlurTextArea(option.id, e.currentTarget.value)}
                          setEdit={params.setEdit}
                          showCharCountOnFocus={true}
                          showCharCountOnHover={true}
                          value={option.name}
                          ariaLabel={t("response option input")}
                        />
                        <DragDropListItemDeleteComponent
                          hover={params.hover}
                          onClick={() => handleRemoveResponseOption(option.id)}
                          ariaLabel={t("delete response option")}
                        />
                      </FeatureFlagBooleanOn>
                    </FeatureFlagBooleanContainer>
                  </Fragment>
                );
              }}
            />
          ) : (
            <Fragment key={option.id} />
          )
        )}
      </DragDropList>

      <TooltipV2
        PopperProps={{ modifiers: { offset: { offset: "0, 20" } } }}
        title={"You can add upto 5 response options"}
        deactivate={!isAddResponseDisabled}
      >
        <span>
          <MiniButton onClick={handleAddResponse} disabled={isAddResponseDisabled}>
            <FeatureFlagBooleanContainer flagName={GCOM_4101_docManagementV2}>
              <FeatureFlagBooleanOff>
                <PlusIcon />
              </FeatureFlagBooleanOff>
              <FeatureFlagBooleanOn>
                <AdditionCircledIcon fillPath={colorPalette.interactions.defaultCta.hex} />
              </FeatureFlagBooleanOn>
            </FeatureFlagBooleanContainer>
            {t("Add response option")}
          </MiniButton>
        </span>
      </TooltipV2>
    </div>
  );
};

export default ResponseOptionsList;
