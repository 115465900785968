import { useEffect, useState } from "react";
import { UUID } from "gx-npm-lib";
import { FormConfigType, ReqDocDTO, ResponseOptionType, SurveyUpdateType } from "../../form.types";
import { useFormContext } from "../form.context";
import { submitFormData } from "./form-submit.lib";

const useFormSubmit = () => {
  const [isFormComplete, setIsFormComplete] = useState(false);
  const {
    companyName,
    hasSurveyRecipientViewed,
    hasUpdates,
    instructions,
    isNotifyingRecipients,
    notificationMessage,
    requestedDocuments,
    responseOptions,
    selectedCategories,
    instructionsAttachedFiles,
  } = useFormContext();

  useEffect(() => {
    setIsFormComplete(!!companyName && !!instructions && responseOptions.length > 0 && selectedCategories.length > 0);
  }, [companyName, instructions, responseOptions, selectedCategories]);

  const handleFormSubmit = async (
    config: FormConfigType,
    onSuccess: (id: UUID) => void,
    onError: (error: string | object) => void = (_error) => {
      /*do nothing*/
    }
  ) => {
    // todo remove post release when validation for empty names is in form
    const cleanedRequestedDocuments: Array<ReqDocDTO> = [];
    requestedDocuments.forEach((doc) => {
      const { id, index, isDeleted, name, updateType, attachedFileId } = doc;
      const reqDocPayload = { id, index, isDeleted, name, updateType, attachedInitFileId: attachedFileId };
      if (reqDocPayload.name) {
        cleanedRequestedDocuments.push(reqDocPayload);
      } else if (config.surveyId && reqDocPayload.updateType !== SurveyUpdateType.NEW) {
        cleanedRequestedDocuments.push({
          ...reqDocPayload,
          isDeleted: true,
          name: "d",
          updateType: SurveyUpdateType.DELETE,
        });
      }
    });
    const cleanedResponseOptions: Array<ResponseOptionType> = [];
    responseOptions.forEach((option) => {
      if (option.name) {
        cleanedResponseOptions.push(option);
      } else if (config.surveyId && option.updateType !== SurveyUpdateType.NEW) {
        cleanedResponseOptions.push({ ...option, isDeleted: true, name: "d", updateType: SurveyUpdateType.DELETE });
      }
    });

    const data = {
      companyName,
      instructions,
      isNotifyingRecipients,
      notificationMessage,
      requestedDocuments: cleanedRequestedDocuments.map((doc, index) => ({ ...doc, index })),
      responseOptions: cleanedResponseOptions.map((option, index) => ({ ...option, index })),
      selectedRequirementCategoryIds: selectedCategories,
      instructionsInitFileIds: instructionsAttachedFiles?.map((file) => file.id),
    };

    await submitFormData(config, data, onSuccess, onError);
  };

  return { handleFormSubmit, hasSurveyRecipientViewed, hasUpdates, isFormComplete };
};

export default useFormSubmit;
