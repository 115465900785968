import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  IconButton,
  TooltipV2,
} from "gx-npm-ui";
import { colorPalette } from "gx-npm-common-styles";
import { TrashcanIcon } from "gx-npm-icons";
import { listItemDeleteButtonStyles as styles } from "./drag-drop-list.styles";
import { GCOM_4101_docManagementV2 } from "../../lib/feature-flags";
import TrashIcon from "../../sections/form/components/request-documents/trash.icon";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => styles);

type DragDropListItemDeleteComponentProps = { hover?: boolean; onClick: () => void; ariaLabel?: string };

const DragDropListItemDeleteComponent = ({
  hover = false,
  onClick,
  ariaLabel,
}: DragDropListItemDeleteComponentProps) => {
  const classes = useStyles();

  const colorIron = colorPalette.neutrals.iron.hex;
  const colorPoisonCherry = colorPalette.status.poisonCherry.hex;
  const { t } = useTranslation();

  return (
    <div aria-label="drag and drop item delete" className={classNames("gx-dlt-btn", classes.root)}>
      <FeatureFlagBooleanContainer flagName={GCOM_4101_docManagementV2}>
        <FeatureFlagBooleanOff>
          <IconButton hoverFill={colorPoisonCherry} onClick={onClick} transparentBackground>
            <TrashcanIcon fillPath={hover ? colorIron : "none"} />
          </IconButton>
        </FeatureFlagBooleanOff>
        <FeatureFlagBooleanOn>
          <TooltipV2
            PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
            placement="top"
            title={t("Delete")}
          >
            <div>
              <IconButton
                rootClassName={"ff4101"}
                ariaLabel={ariaLabel}
                hoverFill={colorPalette.status.poisonCherry.hex}
                onClick={onClick}
                transparentBackground
              >
                <TrashIcon />
              </IconButton>
            </div>
          </TooltipV2>
        </FeatureFlagBooleanOn>
      </FeatureFlagBooleanContainer>
    </div>
  );
};

export default DragDropListItemDeleteComponent;
