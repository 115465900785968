import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { requestDocumentsStyles as styles } from "./request-documents.styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import FormSection from "../form-section/form-section.component";
import FormSectionAbout from "../form-section/form-section-about.component";
import FormSectionLeft from "../form-section/form-section-left.component";
import FormSectionRight from "../form-section/form-section-right.component";
import RequestDocumentsList from "./request-documents-list.component";
import classNames from "classnames";
import RequestedDocumentsListV2Component from "./request-documents-list-v2.component";
import { GCOM_4101_docManagementV2 } from "../../../../lib/feature-flags";
import { FileIcon } from "./file.icon";
import { colorPalette } from "gx-npm-common-styles";

const useStyles = makeStyles(() => styles);
const RequestDocumentsComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div aria-label={t("request documents")}>
      <FormSection>
        <FormSectionLeft>
          <FormSectionAbout
            title={t("Request documents")}
            description={t("Ask recipients to provide documents that support your teams evaluation requirements.")}
          />
          <FeatureFlagBooleanContainer flagName={GCOM_4101_docManagementV2}>
            <FeatureFlagBooleanOn>
              <div className={classes.divider} />
              <div className={classNames(classes.attachedDocTextIconWrapper)}>
                <FileIcon fillPath={colorPalette.accent.huckleberry.hex} />
                <TypographyComponent
                  rootClassName={classes.attachedDocText}
                  element="p"
                  styling="p3"
                  boldness="medium"
                  color={"huckleberry"}
                >
                  {t("You can attach a single document to each request.")}
                </TypographyComponent>
              </div>
            </FeatureFlagBooleanOn>
          </FeatureFlagBooleanContainer>
        </FormSectionLeft>
        <FormSectionRight>
          <TypographyComponent
            boldness={"semi"}
            color={"coal"}
            element={"p"}
            rootClassName={classNames(classes.requestedDocuments)}
          >
            {t("Requested documents")}
          </TypographyComponent>
          <FeatureFlagBooleanContainer flagName={GCOM_4101_docManagementV2}>
            <FeatureFlagBooleanOff>
              <RequestDocumentsList />
            </FeatureFlagBooleanOff>
            <FeatureFlagBooleanOn>
              <RequestedDocumentsListV2Component />
            </FeatureFlagBooleanOn>
          </FeatureFlagBooleanContainer>
        </FormSectionRight>
      </FormSection>
    </div>
  );
};

export default RequestDocumentsComponent;
