import { useEffect, useState } from "react";
import { UUID } from "gx-npm-lib";
import { InstructionFileDTO, SurveyPayloadType } from "../../form.types";
import { useFormContext } from "../form.context";
import { loadFormData } from "./form-load.lib";

const useFormLoad = (initId: UUID, surveyId: UUID) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    setAvailableCategories,
    setCompanyName,
    setHasSurveyRecipientViewed,
    setInstructions,
    setIsExistingSurvey,
    setOriginalSelectedCategories,
    setRequestedDocuments,
    setResponseOptions,
    setSelectedCategories,
    setInstructionsAttachedFiles,
  } = useFormContext();

  useEffect(() => {
    if (!initId) {
      return;
    }
    const loadData = async () => {
      await loadFormData(
        { initId, surveyId },
        (payload: SurveyPayloadType) => {
          setAvailableCategories(payload.availableCategories);
          setCompanyName(payload.companyName);
          setHasSurveyRecipientViewed(payload.hasSurveyRecipientViewed);
          setInstructions(payload.instructions);
          setOriginalSelectedCategories(payload.selectedCategoryIds);
          setSelectedCategories(payload.selectedCategoryIds);
          setResponseOptions(payload.responseOptions);
          setRequestedDocuments(payload.requestedDocuments);
          setInstructionsAttachedFiles(
            payload.attachedInstructionFiles.map((file: InstructionFileDTO) => ({
              id: file.fileId,
              name: file.fileName,
            }))
          );
        },
        () => setHasError(true)
      );
    };
    setIsLoading(true);
    setIsExistingSurvey(!!surveyId);
    loadData().finally(() => setIsLoading(false));
  }, [
    initId,
    setAvailableCategories,
    setCompanyName,
    setHasSurveyRecipientViewed,
    setInstructions,
    setIsExistingSurvey,
    setOriginalSelectedCategories,
    setRequestedDocuments,
    setResponseOptions,
    setSelectedCategories,
    surveyId,
    setInstructionsAttachedFiles,
  ]);

  return { hasError, isLoading, setHasError };
};

export default useFormLoad;
