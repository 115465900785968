import { makeStyles } from "@material-ui/core";
import { formSectionStyles as styles } from "./form-section.styles";
import { ReactNode } from "react";

type FormSectionLeftProps = {
  children?: Array<ReactNode> | ReactNode | number | string | null;
};

const useStyles = makeStyles(() => styles);

const FormSectionLeft = ({ children = null }: FormSectionLeftProps) => {
  const classes = useStyles();
  return <div className={classes.left}>{children}</div>;
};

export default FormSectionLeft;
